@import 'table.scss';
@import 'editor.scss';
@font-face {
  font-family: 'Gilory-Medium';
  src: url('../assets/fonts/Gilroy-Medium.ttf');
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('../assets/fonts/Gilroy-SemiBold.ttf');
}

* {
  font-family: 'Gilory-Medium' !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gilroy-SemiBold' !important;
}
.error-text {
  color: red;
  @media (max-width: 1919px) and (min-width: 100px) {
    font-size: 14px;
    color: red;
  }
}
.error {
  color: red;
  font-size: 14px;
  margin-left: 9px;
  margin-top: -5px;
  margin-bottom: 10px;
}
// .MuiPaper-root > .MuiMenu-paper > .MuiPopover-paper > .MuiPaper-elevation8 > .MuiPaper-rounded{
//   top: 225px;
// }
// .makeStyles-textStyle-244 .MuiInputBase-input{
//   background-color: white;
// }
